import axios from "axios";
import {LOADING_STATUS} from "@/constants/Util";


export const StatsStore = {
    namespaced: true,
    state() {
        return {
            percu_status: LOADING_STATUS.initial,
            percu_data: null
        }
    },
    mutations: {
        changePercuStatus(state, status) {
            state.percu_status = status;
        },
        setData(state, data) {
            state.percu_data = data;
        }
    },
    getters: {
        percu_status(state) {
            return state.percu_status;
        },
        percu_data(state) {
            return state.percu_data;
        }
    },
    actions: {
        async fetchIndicateursDEAL(context, year) {
            try {
                let response = await axios.get(`/api/stats/indicateurs_deal/?year=${year}`, context.rootGetters["auth/getAuth"]);
                return response.data;
            } catch (e) {
                context.dispatch("annonce/annonceError", "Une erreur est survenue", {root: true});
            }
        },
        async fetchFinancementsPercus(context, year) {
            try {
                if(context.state.status === LOADING_STATUS.loading) return;
                context.commit("changePercuStatus", LOADING_STATUS.loading);
                let response = await axios.get(`/api/stats/financements_percus/?year=${year}`, context.rootGetters["auth/getAuth"]);
                context.commit("changePercuStatus", LOADING_STATUS.loaded);
                context.commit("setData", response.data);
                return response.data;
            } catch (e) {
                context.commit("changePercuStatus", LOADING_STATUS.error);
                context.dispatch("annonce/annonceError", "Une erreur est survenue", {root: true});
            }
        },
        async fetchFinancementsEngages(context, year) {
            try {
                let response = await axios.get(`/api/stats/financements_engages/?year=${year}`, context.rootGetters["auth/getAuth"]);
                return response.data;
            } catch (e) {
                context.dispatch("annonce/annonceError", "Une erreur est survenue", {root: true});
            }
        },
        async fetchDossierParEtat(context, year) {
            try {
                let response = await axios.get(`/api/stats/dossier_par_etat/?year=${year}`, context.rootGetters["auth/getAuth"]);
                return response.data;
            } catch (e) {
                context.dispatch("annonce/annonceError", "Une erreur est survenue", {root: true});
            }
        }
    }
}